<template>
  <div class="column-card flex justify-between">
    <div class="card flex flex-col justify-center align-center flex-1" v-for="(item, index) in dataList" :key="index">
      <div
        class="card-box flex justify-center align-center"
        :style="{
          width: width + 'px',
          height: height + 'px'
        }"
      >
        <img
          :src="item.icon"
          :style="{
            width: imgWidth + 'px',
            height: imgHeight + 'px'
          }"
        />
      </div>
      <p class="title">{{ item.title }}</p>
      <p class="desc">{{ item.desc }}</p>
    </div>
  </div>
</template>

<script>
// 一行多列
export default {
  props: {
    width: {
      type: Number,
      default: () => 168
    },
    height: {
      type: Number,
      default: () => 168
    },
    imgWidth: {
      type: Number,
      default: () => 55
    },
    imgHeight: {
      type: Number,
      default: () => 55
    },
    dataList: {
      type: Array,
      default: () => [{}, {}, {}]
    }
  }
};
</script>
<style lang="less" scoped>
.column-card {
  .card-box {
    background: rgba(33, 67, 174, 1);
    border-radius: 50%;
  }
  .card-box:hover {
    background: rgba(34, 78, 219, 1);
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
  }
  img {
    width: 55px;
    height: 55px;
  }
  .title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    margin-top: 23px;
  }
  .desc {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 19px;
  }
}
</style>
