<template>
  <div class="equity-ecology-box h-100 equity-ecology-bo grid grid-flow-col grid-rows-2 grid-cols-3 gap-4">
    <div class="card flex align-end row-start-0 col-start-1 col-span-2">
      <img src="@/assets/img/one-card/1.jpg" />
      <div class="content flex flex-col align-center justify-center">
        <p class="title">年节福利</p>
        <p class="desc">节日福利 年终抽奖</p>
      </div>
    </div>
    <div class="card flex align-end">
      <img src="@/assets/img/one-card/3.jpg" />
      <div class="content flex flex-col align-center justify-center">
        <p class="title">经销商返利</p>
        <p class="desc">销售返利年庆</p>
      </div>
    </div>
    <div class="card flex align-end">
      <img src="@/assets/img/one-card/4.jpg" />
      <div class="content flex flex-col align-center justify-center">
        <p class="title">周年庆福利</p>
        <p class="desc">客户回馈 客户拉新 年庆福利</p>
      </div>
    </div>
    <div class="card flex align-end">
      <img src="@/assets/img/one-card/2.jpg" />
      <div class="content flex flex-col align-center justify-center">
        <p class="title">员工关怀</p>
        <p class="desc">生日祝福 结婚生子</p>
      </div>
    </div>
    <div class="card flex align-end">
      <img src="@/assets/img/one-card/5.jpg" />
      <div class="content flex flex-col align-center justify-center">
        <p class="title">认可激励</p>
        <p class="desc">记功奖金 业绩激励 行政激励</p>
      </div>
    </div>
  </div>
</template>

<script>
// 两行不规则列
export default {};
</script>

<style lang="less" scoped>
.equity-ecology-box {
  .card {
    height: 468px;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition-duration: 300ms;
    }
    img:hover {
      transform: scale(1.1);
      transition-duration: 300ms;
    }
    .content {
      width: 100%;
      height: 168px;
      background: rgba(0, 26, 106, 0.3);
      backdrop-filter: blur(40px);
      text-align: center;
      position: absolute;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        margin: 15px 0 16px 0;
      }
      .desc {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
      }
    }
  }
}
</style>
