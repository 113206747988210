<template>
  <div class="one-card">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">璐付一卡通</h1>
        <h1 class="banner-two-title">一张礼卡一个商城，海量产品随心挑选</h1>
        <div class="banner-two flex">
          <button class="btn">客户送礼</button>
          <button class="btn">员工福利</button>
          <button class="btn">感恩赠礼</button>
          <button class="btn">会员回馈</button>
        </div>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 璐付一卡通使用场景及群体 -->
    <div class="use-people">
      <div class="container mx-auto">
        <h2 class="three-leave-title">璐付一卡通使用场景及群体</h2>
      </div>
      <div class="content">
        <div class="irregular-column container mx-auto">
          <IrregularityColumn />
        </div>
      </div>
    </div>
    <!-- 多场景运用  自定义面额 -->
    <div class="one-card-solutions">
      <div class="container mx-auto">
        <h2 class="three-leave-title">多场景运用 自定义面额</h2>
        <p class="desc">节日、生日、福利、商务等多种卡面定制设计，满足不同场景需求</p>
        <div class="fore-card">
          <ColumnCard :height="116" :width="116" :imgWidth="40" :imgHeight="40" :dataList="columnList" />
        </div>
        <div class="two-card grid grid-rows-2 grid-cols-3 gap-2">
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-two.jpg" />
          </div>
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-three.jpg" />
          </div>
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-one.jpg" />
          </div>
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-six.jpg" />
          </div>
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-five.jpg" />
          </div>
          <div class="img-box">
            <img src="@/assets/img/one-card/mu-fore.jpg" />
          </div>
        </div>
      </div>
    </div>
    <!-- 一卡通 -->
    <div class="one-card-footer">
      <div class="container mx-auto flex">
        <div class="left flex-1">
          <h2 class="three-leave-title">一卡通-璐付卡兑换商城</h2>
          <p class="desc">加入璐付开放平台，获取快捷采购渠道、专属定制权益</p>
          <p class="desc">微信小程序、APP商城，优选海量权益、乐享购物体验。</p>
          <div class="btn-box">
            <button class="btn-line">微信扫码体验</button>
          </div>
        </div>
        <div class="right flex-1">
          <img src="@/assets/img/one-card/iphone.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import ColumnCard from '@/components/common/ColumnCard';
import IrregularityColumn from '@/components/common/IrregularityColumn';

export default {
  components: {
    ColumnCard,
    IrregularityColumn
  },
  data() {
    return {
      columnList: [
        {
          title: '资深设计团队',
          desc: '',
          icon: require('@/assets/img/one-card/one-card-icon-one.png')
        },
        {
          title: '礼赠场景主题',
          desc: '',
          icon: require('@/assets/img/one-card/one-card-icon-two.png')
        },
        {
          title: '企业专属定制',
          desc: '',
          icon: require('@/assets/img/one-card/one-card-icon-three.png')
        },
        {
          title: '展现企业风貌',
          desc: '',
          icon: require('@/assets/img/one-card/one-card-icon-fore.png')
        }
      ]
    };
  },
  mounted() {
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 0, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  }
};
</script>

<style lang="less" scoped>
.one-card {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/one-card/one-card-back.jpeg');
    background-size: 100% 100%;

    // background-position: center;
    // background-size: center center;

    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .banner-two {
        margin: 20px 45px 0 45px;
        .btn {
          width: 97px;
          height: 34px;
          border: 1px solid @lupay-text-color-theme;
          border-radius: 17px;
          margin-right: 10px;
          color: @lupay-text-color-theme;
        }
      }
      .btn-line {
        margin-top: 80px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  .use-people {
    margin-top: 77px;
    .content {
      margin-top: 60px;
      background-color: #f9fcff;
    }
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      //   font-weight: 400;
    }
    .irregular-column {
      margin-top: 95px;
    }
  }
  &-solutions {
    margin-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      //   font-weight: 400;
    }
    .desc {
      width: 720px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      margin-top: 22px;
      margin-left: 49px;
    }
    .two-card {
      margin-top: 37px;
      height: 445px;
      .img-box {
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        transition-duration: 300ms;
      }
      img:hover {
        transform: scale(1.1);
        transition-duration: 300ms;
      }
    }
  }
  &-footer {
    margin-top: 77px;
    height: 686px;
    background: rgba(224, 240, 255, 0.2);
    padding-top: 50px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      margin-bottom: 75px;
    }
    .desc {
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      margin-top: 5px;
      margin-left: 49px;
    }
    .btn-box {
      position: relative;
    }
    .btn-line {
      width: 160px;
      height: 54px;
      margin-top: 80px;
      margin-left: 49px;
      background: linear-gradient(
        125.78deg,
        @lupay-text-color-theme 0%,
        rgba(38, 81, 220, 1) 0%,
        rgba(231, 238, 252, 1) 100%
      );
      position: relative;
    }
    .btn-line:hover::after {
      content: '';
      display: block;
      width: 160px;
      height: 160px;
      top: 80px;
      left: -1px;
      position: absolute;
      background-image: url('../../assets/img/qrcode.jpeg');
      background-size: 100% 100%;
      padding: 2px;
      border: 1px solid #f4f4f4;
      opacity: 1;
      transition-duration: 300ms;
    }
    .right {
      img {
        // height: 780px;
        // width: 862px;
      }
    }
  }
  .fore-card {
    margin-top: 40px;
  }
}
</style>
