var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-card flex justify-between"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"card flex flex-col justify-center align-center flex-1"},[_c('div',{staticClass:"card-box flex justify-center align-center",style:({
        width: _vm.width + 'px',
        height: _vm.height + 'px'
      })},[_c('img',{style:({
          width: _vm.imgWidth + 'px',
          height: _vm.imgHeight + 'px'
        }),attrs:{"src":item.icon}})]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }